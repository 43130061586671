@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

html,body{
  overflow-x: hidden;
}
body {
  background: url("/public/images/bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
 
}

.logo-container{
  width: 150px;
  overflow: hidden;
  display: flex;
  justify-content: center;

}
.d-flex{
  display: flex;
gap: 20px;
}
nav .logo {
  width: 150px;
  cursor: pointer;
}
nav .logo:hover{
  width: 170px;
  transition: 0.5s;
}
nav .profile-img {
  width: 60px;
  border-radius: 50%;
  margin-top: 12px;
}

.section1{
  width: 100%;
}
nav {
  position: relative;

  width: 280px;
  height: 100vh;
  
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(15px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transition: all 0.5s ease;
}

.flex-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

nav ul {
  /* padding-inline: 12px; */
  display: flex;
  flex-direction: column;
}

nav ul li {
  width: 115%;
  list-style: none;
padding: 15px 25px;
  color: white;
  font-size: 22px;
  font-weight: 700;

  cursor: pointer;
  border-radius: 12px;
  transition: all 0.2s ease;
  /* clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%); */
}

nav ul li:active {
  transform: scale(0.96);
}
.hamburger-container{
  width: 100%;
  display: flex;
  justify-content: start;
}
.ham {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  z-index: 200;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(15px);
  margin: 10px;
  border: none;
  border-radius: 50%;
  transition: all 0.5s ease;
  cursor: pointer;
  display: none;
}
.ham:active,
.ham .menu-btn:active {
  transform: scale(0.96);
}
.ham .menu-btn {

  color: white;
  
  
  font-size: 42px;
  cursor: pointer;
}


nav ul li:hover {
  background: linear-gradient(
    160deg,
    rgba(255, 255, 255, 0.22) 6.04%,
    rgba(255, 255, 255, 0.3) 105.91%
  );


  backdrop-filter: blur(18.87152862548828px);
}

nav ul li a {
  margin-left: 12px;
  text-decoration: none;
  white-space: nowrap;
  color: white;
}

nav .profile-container {
  width: 90%;
  height: 150px;
  text-align: center;
  border-radius: 12px;
  background: linear-gradient(
    160deg,
    rgba(255, 255, 255, 0.22) 6.04%,
    rgba(255, 255, 255, 0.3) 105.91%
  );
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  backdrop-filter: blur(18.87152862548828px);
}

nav .profile-container h4 {
  /* margin-top: 12px; */
  font-size: 15px;
  font-weight: 700;

  color: white;
}

nav .profile-container p {
  font-size: 13px;
  font-size: 13px;
  font-weight: 500;

  color: white;
}

.btn-login-user button {
  position: relative;
  padding: 10px 22px;
  border-radius: 6px;
  border: none;
  color: #fff;
  cursor: pointer;
  background-color: #7d2ae8;
  transition: all 0.2s ease;
  font-weight: bolder;
  font-size: 15px;
  margin-left: 5px;
}
.btn-login-user button:active {
  transform: scale(0.96);
}

/* nav2 */
.nav2 .logo {
  width: 150px;
}
.nav2 .profile-img {
  width: 60px;
  border-radius: 50%;
  /* margin-top: 12px; */
}

.crossicon {
  display: flex;
  justify-content: start;
  
  padding-left: 10px;
  align-items: center;
 width: 100%;
 height: 50px;
  
  border: none;
 
  transition: all 0.2s ease;
  cursor: pointer;
  /* display: none; */
}
.crossicon:active,
.crossicon .icon:active {
  transform: scale(0.96);
}
.crossicon .icon {

  color: rgba(255, 255, 255, 0.74);
 
  font-size: 45px;
  cursor: pointer;
}
.crossicon span{
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: black; */
}
.nav2 {
  width: 280px;
  height: 100vh;
  flex-shrink: 0;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(15px);
  position:absolute;
  z-index: 1000;
  transform: translateX(-100%);
  opacity: 0;
  transition: all 0.5s ease;
}

 .nav2 ul {
  display: flex;
  flex-direction: column;
} 
.nav2 ul li {
  width: 115%;
  list-style: none;
  padding: 15px 30px;
  color: white;
  font-size: 22px;
  font-weight: 700;
  clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
  cursor: pointer;
  border-radius: 12px;
  transition: all 0.2s ease;
} 
.nav2 ul li:active {
  transform: scale(0.96);
}
.nav2 ul li:hover {
  background: linear-gradient(
    160deg,
    rgba(255, 255, 255, 0.22) 6.04%,
    rgba(255, 255, 255, 0.3) 105.91%
  );


  backdrop-filter: blur(18.87152862548828px);
}
.nav2 ul li a {
  margin-left: 12px;
  text-decoration: none;
  white-space: nowrap;
  color: white;
}
.nav2 .profile-container {
  width: 90%;
  height: 150px;
  text-align: center;
  border-radius: 12px;
 
background-color: rgba(255, 255, 255, 0.212);
  backdrop-filter: blur(14px);
}
.nav2 .profile-container h4 {
  /* margin-top: 12px; */
  font-size: 15px;
  font-weight: 700;

  color: white;
}

.nav2 .profile-container p {
  font-size: 13px;
  font-size: 13px;
  font-weight: 500;

  color: white;
} 
.nav2 .btn-login-user button {
  position: relative;
  padding: 10px 22px;
  border-radius: 6px;
  border: none;
  color: #fff;
  cursor: pointer;
  background-color: #7d2ae8;
  font-weight: bolder;
  font-size: 15px;
  transition: all 0.2s ease;
}
.active-box {
  transform: translateX(0%);
  opacity:1;
}


.needAssessmentContainer {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.socials {
  display: flex;
  gap: 26px;
  justify-content: right;
  
}
.socials .icon {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(15px);
  border-radius: 50%;
  backdrop-filter: blur(15px);
  border: none;
  width: 50px;
  height: 50px;
  color: #fff;
}
.socials .icon i {
  font-size: 28px;
}


.home-page {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;

  color: white;
}

.home-page .socials {
  display: flex;
  gap: 26px;
  justify-content: end;
  padding-top: 10px;
}
.home-page .socials .icon {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(15px);
  border-radius: 50%;
  border: none;
  width: 40px;
  height: 40px;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
}
.home-page .socials .icon:active {
  transform: scale(0.96);
}
.home-page .socials .icon i {
  font-size: 20px;
}

.home-container {
  width: 100%;
  margin-top: 20px;
  padding: 20px;
  margin-bottom: 12px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(15px);
}

.home-container h3{
  margin-bottom: 12px;
}

.home-container .therapy-section {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: none;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  backdrop-filter: blur(15px);
}

.therapy-item .icon p {
  font-size: 14px;
}

.therapy-section .therapy-title {
  width: 100px;
  height: 50px;
}

.therapy-section .therapy-title h4 {
  color: white;
}

.therapy-section .therapy-item {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
  padding: 15px;
}

.therapy-section .therapy-item .icon {
  width: 80px;
  height: 80px;
  /* margin: 12px; */
  text-align: center;
  border: none;
  border-radius: 22px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.therapy-section .therapy-item .icon:active {
  transform: scale(0.96);
}
.therapy-section .therapy-item .icon i {
  font-size: 30px;
  margin-top: 12px;
}

.therapy-item .art {
  background-image: linear-gradient(135deg, #feb692 10%, #ea5455 100%);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.therapy-item .music {
  background-image: linear-gradient(135deg, #abdcff 10%, #0396ff 100%);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.therapy-item .movement {
  background-image: linear-gradient(135deg, #ce9ffc 10%, #7367f0 100%);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.therapy-item .nature {
  background-image: linear-gradient(135deg, #81fbb8 10%, #28c76f 100%);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

/* home down sectio sytle */
.home-downpart {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  gap: 15px;
}
.home-downpart .home-downleftsection {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.home-downleftsection .calender {
  width: 100%;
  height: 280px;
  padding: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  color: white;
  border: none;
  border-radius: 8px;
  background-image: linear-gradient(135deg, #ff9d6c 10%, #bb4e75 100%);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  backdrop-filter: blur(15px);
}

.home-downleftsection .publisher-desk {
  width: 100%;
  height: 270px;
  padding: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 12px;
  color: white;
  border: none;
  border-radius: 8px;
  background-image: linear-gradient(135deg, #97abff 10%, #123597 100%);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  backdrop-filter: blur(15px);
}

/* right section style */
.home-downrightsection {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.home-downrightsection .performance-box {
  width: 100%;
  padding: 12px;
}
.home-downrightsection .image-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
  padding: px;
}

.image-group {
  position: relative;
  top: 200px;
  transform: rotate(-133deg);
}
.image-group .redcircle {
  position: absolute;
  width: 270px;
}
.image-group .purplecircle {
  width: 220px;
  position: absolute;
}
.image-group .skybluecircle {
  width: 170px;
  position: absolute;
}
.image-group .greencircle {
  width: 150px;
  position: absolute;
}

.home-downrightsection .testimonials {
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border: none;
  border-radius: 12px;
  background-image: linear-gradient(135deg, #69ff97 10%, #00e4ff 100%);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  backdrop-filter: blur(15px);
  padding: 18px;
}

.home-downrightsection .testimonials input {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 20px;
  padding: 8px 5px;
  background-color: white;
}
.w-100{
  width: 100%;
}
.form-page {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
}

.form-page .socials {
  display: flex;
  gap: 26px;
  justify-content: end;
  
}
.form-page .socials .icon {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(15px);
  border-radius: 50%;
  border: none;
  width: 50px;
  height: 50px;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
}
.form-page .socials .icon:active {
  transform: scale(0.96);
}
.form-page .socials .icon i {
  font-size: 28px;
}

.form-container {
  width: 100%;
  margin: auto;
  padding: 10px;
  margin-bottom: 12px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(15px);
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 22px;
}

.form-container img {
width: 500px;
}

.form-main-section {
  width: 50%;
  padding: 12px;

  backdrop-filter: blur(15px);
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border: none;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.form-main-section .form-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  width: 90%;
}

.form-main-section .parent-container,
h3 {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

#pname,#age,#dob,.parent-container,#location,#img-file,.parent-container #father-name,.parent-container #mother-name{
  margin-bottom: 12px;
}

.dob-input{
  margin-top:12px;
}
.form-details .gender-container,
h3 {
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
  gap:10px;
}

.form-details label {
  font-size: 18px;
  font-weight: 500;
}

.form-details input,
textarea {
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 5px;
}
#img-file {
  margin-top: -10px;
}

input::file-selector-button {
  font-weight: bold;
  width: 60%;
  padding: 10px;
  background-color: white;
  color: rgb(138, 42, 42);
  border: none;
  border-radius: 5px;
}

.form-details button {
  position: relative;
  text-align: center;
  font-weight: 700;
  width: 100px;
  margin: auto;
  padding: 10px 22px;
  border-radius: 6px;
  border: none;
  color: rgb(255, 255, 255);
  cursor: pointer;
  background-color: #7d2ae8;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  transition: all 0.2s ease;
}
.form-details button:active {
  transform: scale(0.96);
}


.section1 {
  height: 100vh;
}
.needAssessmentContainer {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.socials {
  display: flex;
  gap: 26px;
  justify-content: right;
padding: 5px;
}
.socials .icon {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(15px);
  border-radius: 50%;
  backdrop-filter: blur(15px);
  border: none;
  width: 50px;
  height: 50px;
  color: #fff;
}
.socials .icon i {
  font-size: 28px;
}
.assessmentContainer {
  width: 100%;
  padding-bottom: 50px;
  margin: auto;
  border-radius: 5px;
  /* background: rgba(255, 255, 255, 0.2); */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(15px);
}
.assessmentContainer .assessmentHeading {
  color: #fff;
  padding: 10px 0 10px 30px;
  font-weight: 500;
  font-size: 34px;
  height: 67px;
}
.assessmentContainer .famTeaBtn {
  display: flex;
  gap: 30px;
  justify-content: center;
}
.assessmentContainer .family,
.assessmentContainer .teacher {
  background-color: transparent;
  border: none;
  opacity: 70%;
  color: #fff;
  font-size: 16px;
  z-index: 11;
}
.assessmentContainer .active,
.assessmentContainer .family:hover,
.assessmentContainer .teacher:hover {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  opacity: 100%;
  z-index: 11;
  border: none;
}
.assessmentContainer .family:focus,
.assessmentContainer .teacher:focus {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  opacity: 100%;
  z-index: 11;
  border: none;
}
.assessmentContainer .hr1 {
  background-color: #fff;
  height: 3px;
  padding: 0 2px;
  border-width: 0;
  opacity: 37%;
  margin: 10px 0 30px 0;
}
.assessmentContainer .questions {
  width: 100%;
  height: 500px;
  padding: 0 5%;
  margin: auto;
  border: none;
  overflow-y: scroll;
  scrollbar-color: rgba(255, 255, 255, 0.4) #e0e0e000;
  scrollbar-width: auto;
  scrollbar-gutter: stable;
  
}
.assessmentContainer .questions::-webkit-scrollbar{

  width: 5px;
  height: 15px;
 
}

.assessmentContainer .questions::-webkit-scrollbar-thumb{
  background-color: rgb(141, 63, 159);
}
.assessmentContainer .questions p {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 20px;
}
.assessmentContainer .questions #Family .q3,
.assessmentContainer .questions #Family .q5,
.assessmentContainer .questions #Family .q10,
.assessmentContainer .questions #Family .q26,
.assessmentContainer .questions #Family .q27 {
  display: flex;
  align-items: center;
}
.assessmentContainer .questions .question select {
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(15px);
  border: none;
  width: 200px;
  border-radius: 5px;
  height: 30px;
  padding: 0 10px;
  font-size: 16px;
  float: right;
}
.assessmentContainer .questions .question .radio {
  display: flex;
  gap: 30px;
}
.assessmentContainer .questions .question .yesRadio,
.assessmentContainer .questions .question .noRadio,
.assessmentContainer .questions .question .introvertRadio,
.assessmentContainer .questions .question .extrovertRadio {
  display: flex;
  gap: 10px;
  color: #fff;
}

.assessmentContainer .questions #Teacher h3 {
  color: #fff;
}
.assessmentContainer .questions #Teacher .q1 .q1B,
.assessmentContainer .questions #Teacher .q2 .q2C,
.assessmentContainer .questions #Teacher .q3 .q3B {
  display: flex;
  align-items: center;
}

.assessmentContainer .quest {
  background-color: transparent;
  border: none;
  color: #fff;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  width: 100%;
  margin: auto;
  cursor: pointer;
  padding: 10px 10px;
  text-align: left;
  outline: none;
  font-size: 14px;
  transition: 0.4s;
  font-weight: 400;
}
.assessmentContainer .input2 {
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  width: 100%;
  height: 70px;
  padding: 10px;
  resize: none;
  color: #fff;
  outline: none;
}
.assessmentContainer .hr2 {
  background-color: rgba(255, 255, 255, 0.2);
  height: 2px;
  width: 100%;
  border-width: 0;
  margin: 10px 0 5px 0;
}
.assessmentContainer .quest:after {
  color: #777;
  font-weight: bold;
  float: right;
}
.assessmentContainer .panel {
  width: 100%;
  margin: auto;
  border-radius: 15px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.assessmentContainer .review {
  font-weight: 600;
  font-size: 16px;
}

.sectionArtContainer {
  width: 100%;
  /* margin-right: 80px; */
  margin: auto;
  /* float: right; */
  display: flex;
  flex-direction: column;

}
.socials {
  display: flex;
  gap: 26px;
  justify-content: right;

}
.socials .icon {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(15px);
  border-radius: 50%;
  border: none;
  width: 50px;
  height: 50px;
  color: #fff;
}
.socials .icon i {
  font-size: 28px;
}
.artContainer::-webkit-scrollbar{
  width: 7px;

}
.artContainer::-webkit-scrollbar-thumb{
 background-color: rgba(109, 99, 99, 0.445);
 backdrop-filter: blur(14px);
}
.artContainer::-webkit-scrollbar-track{
  background-color:rgb(186, 136, 160);
}
.artContainer {
  width: 100%;
  
  padding-bottom: 50px;
  margin: auto;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(15px);
  overflow-y: scroll;
  scrollbar-color: rgba(255, 255, 255, 0.4) #e0e0e000;
  scrollbar-width: auto;
  scrollbar-gutter: stable;
}
.artContainer .part1 {
  display: flex;
  gap: 20px;
  align-items: center;
  padding-left: 30px;
  height: 67px;
  position: sticky;
  top: 0;
  z-index: 200;

transition: 0.5s background-color;
}
.artContainer .backBtn {
  border: none;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(15px);
  height: 26px;
  width: 70px;
  color: #fff;
}
.artContainer .artHeading {
  color: #fff;
  padding: 10px 0 10px 0;
  font-weight: 600;
  font-size: 26px;
}
.part2 {
  grid-area: qp;
}
.part3 {
  grid-area: prog;
}
.part4 {
  grid-area: pr;
}
.part5 {
  grid-area: ps;
}
.artContainer .artProgress {
  display: grid;
  grid-template-areas:
    "qp qp prog"
    "pr pr prog"
    "ps ps ps";
  gap: 21px;
  width: 95%;
  margin: auto;
}
.env-icon{
  color: white;
  text-decoration: none;
}
.artContainer .part3{
  height: 100%;
}
.artContainer .part2,
.artContainer .part3,
.artContainer .part4,
.artContainer .part5 {
  border: none;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(15px);
  margin: auto;
  color: #fff;
}
.artContainer .part2Heading,
.artContainer .part3Heading,
.artContainer .part4Heading,
.artContainer .part5Heading {
  font-size: 16px;
  text-shadow: 1px 1px 5px #000;
  padding: 10px 0 5px 20px;
}
.artContainer .part2,
.artContainer .part4 {
  width: 100%;
}
.artContainer .part2 i {
  font-size: 30px;
  color: rgb(234, 118, 111);
  color: linear-gradient(
    120deg,
    rgba(234, 118, 111, 1) 0%,
    rgba(95, 55, 255, 1) 100%
  );
}
.artContainer .part2 .fa-quote-left {
  padding-left: 20px;
}
.artContainer .part2 p {
  width: 70%;
  margin: auto;
  font-size: 14px;
  margin-bottom: 20px;
}
.artContainer .part2 .fa-quote-right {
  float: right;
  padding-right: 50px;
}
.artContainer .part3 .checks {
  width: 70%;
  float: right;
  padding-right: 20px;
  text-align: center;
}
.artContainer .part3 .checks p {
  font-size: 14px;
  font-weight: 600;
}
.artContainer .participantTable {
  width: 100%;
  border-spacing: 0 0.7em;
  margin-bottom: 20px;
}
.artContainer .participantTable .heading {
  font-weight: 500;
  text-align: center;
}
.artContainer .participantTable .heading th {
  padding: 10px 0;
}
.artContainer .participantTable td {
  text-align: center;
  padding: 10px 0;
}
.artContainer .part4 .participantTable .tableBackground {
  border: none;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(15px);
}
.artContainer .part5 {
  width: 100%;
  padding-bottom: 20px;
}
.artContainer .part5 .progressBars {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 95%;
  margin: auto;
}
.artContainer .part5 p {
  margin-bottom: 0px;
  text-shadow: 0 1px 5px #000;
  font-weight: 600;
}
.artContainer .part5 .bar1,
.artContainer .part5 .bar2,
.artContainer .part5 .bar3,
.artContainer .part5 .bar4 {
  margin: auto;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.artContainer .part5 .bar1 .progress,
.artContainer .part5 .bar2 .progress,
.artContainer .part5 .bar3 .progress,
.artContainer .part5 .bar4 .progress {
  width: 80%;
  border-radius: 30px;
  background-color: #fff;
}
.artContainer .part5 .bar1 .progress .progress-bar,
.artContainer .part5 .bar2 .progress .progress-bar,
.artContainer .part5 .bar3 .progress .progress-bar,
.artContainer .part5 .bar4 .progress .progress-bar {
  background: rgb(234, 118, 111);
  background: linear-gradient(
    120deg,
    rgba(234, 118, 111, 1) 0%,
    rgba(95, 55, 255, 1) 50%
  );
  border-radius: 30px;
}
.sectionArt{
  width: 100%;
}

.sectionDetailsContainer {
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.socials {
  display: flex;
  gap: 26px;
  justify-content: right;
  padding-top: 30px;
}
.socials .icon {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(15px);
  border-radius: 50%;
  border: none;
  width: 50px;
  height: 50px;
  color: #fff;
}
.socials .icon i {
  font-size: 28px;
}
.detailsContainer::-webkit-scrollbar{
  width: 5px;
}
.detailsContainer::-webkit-scrollbar-thumb{
  background-color: rgb(149, 75, 148);
}
.detailsContainer::-webkit-scrollbar-corner{
  display: none;
}
.detailsContainer::-webkit-scrollbar-track{
  background-color: transparent;

}
.detailsContainer {
  width: 100%;
  height: 650px;
  padding-bottom: 50px;
  margin: auto;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(15px);
  overflow-y: scroll;
  scrollbar-color: rgba(255, 255, 255, 0.4) #e0e0e000;
  scrollbar-width: auto;
  scrollbar-gutter: stable;
}
.detailsContainer .details_desc {
  background-image: url("/public/details.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 250px;
  display: flex;
  align-items: center;
}
.detailsContainer .details_desc .details_descc {
  background: rgba(79, 79, 79, 0.5);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  width: 100%;
  margin: auto;
  padding: 20px 60px;
}
.detailsContainer .details_desc .details_descc .details_desc_heading {
  color: #fff;
  font-size: 32px;
  text-align: center;
}
.detailsContainer .details_desc .details_descc .details_desc_p {
  color: #fff;
  text-align: center;
  font-size: 12px;
}
.detailsContainer .details_services {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;
  padding: 0 70px;
  margin: 30px 0;
}
.detailsContainer .details_services button {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(15px);
  border-radius: 10px;
  border: none;
  border-top: 1px solid #fff;
  width: 50px;
  height: 50px;
  color: #fff;
}
.detailsContainer .details_services button i {
  font-size: 22px;
}
.detailsContainer .details_services .service1,
.detailsContainer .details_services .service2,
.detailsContainer .details_services .service3,
.detailsContainer .details_services .service4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.detailsContainer .details_services .service1 .service1_heading,
.detailsContainer .details_services .service2 .service2_heading,
.detailsContainer .details_services .service3 .service3_heading,
.detailsContainer .details_services .service4 .service4_heading {
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
}
.detailsContainer .details_services .service1 .service1_desc,
.detailsContainer .details_services .service2 .service2_desc,
.detailsContainer .details_services .service3 .service3_desc,
.detailsContainer .details_services .service4 .service4_desc {
  color: #fff;
  font-size: 12px;
}
.detailsContainer .details_image {
  background-image: url("/public/details2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 140px;
  width: 90%;
  border-radius: 40px;
  margin: auto;
}
@media screen and (max-width: 1500px) {

}
@media screen and (max-width: 1100px) {
  .form-page {
    width: 800px;
  }
  .form-container img {
    width: 200px;
  }
  .home-page {
    width: 100%;
  }
  .therapy-section .therapy-title{
    display: none;
  }
  .therapy-section .therapy-item {
  width: 100%;
  justify-content:center;
}
}
@media screen and (max-width: 1200px) {
  .sectionArtContainer {
    width: 1000px;
  }
  .needAssessmentContainer {
    width: 1000px;
  }
  nav {
    display: none;
  }
  .ham {
    display: flex;
  }
}



@media screen and (max-width: 1000px) {
  .needAssessmentContainer {
    width: 800px;
  }

  .sectionArtContainer {
    width: 800px;
  }
  .artContainer .artProgress {
    display: grid;
    grid-template-areas:
      "qp qp qp"
      "prog prog prog"
      "pr pr pr"
      "ps ps ps";
    gap: 21px;
    width: 90%;
    margin: auto;
  }
}



@media screen and (max-width: 800px) {
  .sectionArtContainer {
    width: 600px;
  }
  .form-page {
    width: 500px;
  }
  .needAssessmentContainer {
    width: 600px;
  }
  .form-container {
    flex-direction: column;
  }
  .form-main-section{
    width: 100%;
  }
}


@media screen and (max-width: 600px) {
  .sectionArtContainer {
    width: 400px;
  }
  .needAssessmentContainer {
    width: 400px;
  }
  .assessmentContainer .questions #Teacher .q1 .q1B,
  .assessmentContainer .questions #Teacher .q2 .q2C,
  .assessmentContainer .questions #Teacher .q3 .q3B {
    display: flex;
    flex-direction: column;
  }
  .assessmentContainer .questions #Family .q3,
  .assessmentContainer .questions #Family .q5,
  .assessmentContainer .questions #Family .q10,
  .assessmentContainer .questions #Family .q26,
  .assessmentContainer .questions #Family .q27 {
    display: flex;
    flex-direction: column;
  }
  .form-page {
    width: 350px;
  }
  .form-main-section .form-details {
    align-items: center;
  }
  .form-details .gender-container {
    padding-right: 90px;
  }
  .form-details textarea {
    width: 72%;
  }
  .form-details .age-input {
    padding-right: 40px;
  }
  .form-details .dob-input {
    padding-right: 75px;
  }
  .form-details .p-input {
    padding-right: 25px;
  }
  #img-file {
    padding-left: 30px;
  }
  .form-details .p-img-input {
    padding-right: 19px;
  }
  input::file-selector-button {
    padding: 5px;
    margin-left: 22px;
    width: 30%;
  }
  .w-100{
    width: 100%;
  }
  .home-downpart{
    flex-direction: column;
  }
  .home-downpart .home-downleftsection{
    width: 100%;
  }
  .home-downpart .home-downrightsection{
    width: 100%;
  }
  .d-flex{
    gap: 0;
  }
}



@media screen and (max-width: 400px) {
  .sectionArtContainer {
    width: 350px;
  }
  .home-page{
    margin-left: 10px;
  }
  .needAssessmentContainer {
    width: 350px;
  }
}
@media screen and (max-width: 350px) {
  .needAssessmentContainer {
    width: 340px;
  }
  .sectionArtContainer {
    width: 340px;
  }
}

@media  (min-width:1101px) {
  nav {
    display: flex;
  }
  .nav2{
    display: none;
  }
  .ham{
    visibility: hidden;
  }
}
